import React from "react";
import Image from "next/image";

const ÜberunsMitgliedschaften = () => {
  return (
    <>
      <div className="partner-area ptb-70 bg-f8fbfa">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-12">
              <div className="partner-title center-image">
                <h3>Mitglied in:</h3>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 center-image">
                  <Image
                    src="/images/about/schleswigholstein_startupverband.png"
                    className="responsive-img"
                    alt="logo - schleswig holstein startup verband"
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: 'auto', height: '100px' }} />

            </div>
             <div className="col-lg-5 col-md-12 center-image">
                  <Image
                    src="/images/about/Logo_DiWiSH_Mitglied.png"
                    className="responsive-img"
                    alt="logo - diWiSH mitglied"
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: 'auto', height: '100px' }} />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ÜberunsMitgliedschaften;
